<template>
  <div style="width: 100%">
    <v-overlay :value="isLoading" >
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
            :width="3"
            color="red"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="font-weight-bold">
          <p>{{ $t("Checkout.wait") }}</p>
        </v-col>
      </v-row>
    </v-overlay>

    <n-panels>
      <template #left>
        <filters-panel v-model="filters" :entidad-id="tblLgtOc.tblEntEntidad.entidadId" v-if="!isDisabled && tblLgtOc.tblEntEntidad"></filters-panel>
      </template>
      
      <template #center>
        <div width="100%" v-if="!isDisabled && tblLgtOc.tblEntEntidad">
          <n-grid
            :label="$t('Home.title1')"
            :src="productsUrl"
            :filters="{
              'precioUnitario': filters.precioUnitario.join(':'),
              'producto': filters.producto,
              'tblLgtGrpProducto.grpProductoId': filters.categories.join(',')
            }"
            :filter-operators="{
              'precioUnitario': 'between',
              'producto': 'like',
              'tblLgtGrpProducto.grpProductoId': 'in'
            }"
            order-by="precioUnitario"
            :sort-by="filters.sortBy"
            @reset="() => selected = []"
            col-sm="12"
            col-md="12"
            col-lg="12"
            col-xl="12"
          >
            <template #append-header="{ isLoading }">
                <v-btn
                  class="ml-3 text-capitalize"
                  rounded
                  color="primary"
                  @click="() => openAppointmentsDialogSingle()"
                  :loading="isLoading"
                  v-if="selected.length > 0"
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ $t("Grid.add") }}
                </v-btn>
                
              <v-toolbar-items v-else>

                <v-text-field v-model="filters.producto" :placeholder="$t('AppBar.search')" outlined rounded dense
                  clearable hide-details single-line prepend-inner-icon="mdi-magnify" :disabled="isLoading">
                </v-text-field>
              </v-toolbar-items>
            </template>

            <template #item="{ item }">
              <v-card class="shadow-sm">
                <v-list-item>

                  <v-list-item-action>
                    <v-checkbox v-model="selected" :value="item"></v-checkbox>
                  </v-list-item-action>
                
                  <v-img
                    :src="item.imagen || '/img/default_product_image.png'"
                    class="mr-4"
                    max-width="52"
                    min-width="52"
                    :aspect-ratio="1"
                  ></v-img>

                  <v-list-item-content>
                    <span class="text-uppercase font-weight-regular text-caption">
                      <v-icon small v-if="item.esAgendable === 'Y' && item.esCalendarizado == 'Y'">mdi-calendar-check</v-icon>
                      <v-icon small
                        v-else-if="item.esAgendable == 'Y' && item.esCalendarizado == 'N'">mdi-calendar-remove</v-icon>
                      {{ item.producto }}
                    </span>

                    <div class="font-weight-regular text-caption" v-text="item.tblLgtGrpProducto.grpProducto" v-if="item.tblLgtGrpProducto"></div>

                    <div class="font-weight-bold text-caption" v-text="`$ ${(item.precioNeto || 0).toFixed(2)}`" v-if="item.precioNeto"></div>
                  </v-list-item-content>

                  <v-list-item-action v-if="isSelected(item)">
                    <v-text-field
                      v-model="item.cantidadCaja"
                      type="number"
                      :min="1"
                      dense
                      hide-details
                      outlined
                      @keydown="(evt) => {
                        if (['e', 'E', '+', '-'].includes(evt.key)) {
                          evt.preventDefault();
                        }
                      }" 
                      @change="(v) => {
                        if (v == undefined || v == null || v == '') {
                          item.cantidadCaja = 0;
                        }
                      }"
                    >
                    </v-text-field>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </template>
          </n-grid>
        </div>
      </template>

      <template #right>

        <div class="pa-2">
          <v-form ref="checkoutForm">
            <v-row>
              <n-date-picker
                v-if="isScheduleable"
                v-model="delivery.date"
                :label="$t('Checkout.deliveryDate')"
                :rules="[$v.required, $v.futureOrEquals()]"
                cols="12"
              ></n-date-picker>

              <n-time-picker
                v-if="isScheduleable"
                v-model="delivery.time"
                :label="$t('Checkout.deliveryTime')"
                :rules="[
                  $v.required,
                  $v.futureOrEquals(
                    null,
                    'YYYY-MM-DD HH:mm',
                    (time) => `${delivery.date} ${time}`
                  ),
                ]"
                cols="12"
              ></n-time-picker>
            </v-row>
          </v-form>
        </div>

        <order-details
          :items="items"
          :editable="false"
          price-key="precioUnitario"
          enable-delete
          @delete="(item)=>removeFromCart(item)"
        >
          <template #actions="{ count }">
            <v-btn
              color="primary"
              class="text-capitalize font-600 mb-4"
              block
              @click="() => generateOrder()"
              :disabled="count < 1"
            >
              {{ $t("Checkout.placeOrder") }}
            </v-btn>
          </template>
        </order-details>
      </template>
    </n-panels>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import OrderDetails from "../../components/OrderDetails.vue";
import NAppointmentDialog from "../../components/newgen/NAppointmentDialog";
import NPanels from '../../components/newgen/NPanels.vue';
import NGrid from '../../components/newgen/NGrid.vue';
import FiltersPanel from "../home/FiltersPanel";

export default {
  components: {
    NPanels,
    NGrid,
    FiltersPanel,
    OrderDetails
  },
  watch: {
    "$route.params.ocId": {
      immediate: true,
      handler() {
        this.fetchOrder();
      },
    },
  },
  computed: {
    productsUrl() {
      const cltId = this.tblLgtOc.cltId;
      const entidadId = this.tblLgtOc.tblEntEntidad.entidadId;
      const comercioId = this.tblLgtOc.comercioId;

      let url = `api/v1/public/entities/${entidadId}`;

      if(comercioId != null && comercioId != undefined && comercioId != ''){
        url += `/commerces/${comercioId}`;
      }

      if(cltId != null && cltId != undefined && cltId != ''){
        url += `/clients/${cltId}`;
      }

      url += '/products';

      return url;
    }
  },
  data() {
    return {
      filters: {
        precioUnitario: [0, 10000],
        categories: [],
        sortBy: 'asc'
      },
      products: [],
      items: [],
      selected: [],
      headers: [
        {
          text: "",
          value: "imagen",
        },
        {
          text: this.$t("Grid.product"),
          align: "start",
          value: "producto",
        },
        {
          text: this.$t("Grid.category"),
          align: "start",
          value: "tblLgtGrpProducto.grpProducto",
        },
        {
          text: this.$t("Grid.price"),
          align: "left",
          value: "precioNeto",
        },
        {
          text: this.$t("Grid.unit"),
          align: "left",
          value: "medidaId",
        },
        {
          text: this.$t("Grid.quantity"),
          align: "left",
          value: "cantidadCaja",
          width: "200px",
        },
      ],
      tblLgtOc: {},
      delivery: {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm"),
      },
      finishSimpleCheckoutFlag: false,
      isScheduleable: false,

      isLoading: true,
      isDisabled: true,
    };
  },
  methods: {
    isSelected(item){
      return _.findIndex(this.selected, (i) => {
        return i.ocDtlId == item.ocDtlId; 
      }) > 0
    },
    fetchOrder() {
      const ocId = this.$route.params.ocId;

      this.isDisabled = true;
      
      this.isLoading = true;

      axios
        .get(`api/v1/public/orders/${ocId}`)
        .then((res) => {
          let tblLgtOc = _.get(res, "data.data.order", {});
          let details = _.get(res, "data.data.details", {});
          this.checkIsScheduleable(details);

          try {
            let fechaOc = (tblLgtOc.fechaOc || "").split(" ");

            this.delivery.date = fechaOc[0];
            this.delivery.time = fechaOc[1];
          } catch {
            this.$console.error("Wrong datetime");
          }

          this.tblLgtOc = tblLgtOc;
          this.items = details;

          this.isDisabled = false;
        })
        .catch(() => {
          this.isDisabled = true;

          this.tblLgtOc = {};

          this.$router.push("/orders").catch(() => {});
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    getMedida(item) {
      const medidaId = item.medidaId;

      let str = "";

      if (medidaId == "C") {
        str = this.$t("Grid.piece");
      } else if (medidaId == "Y") {
        str = this.$t("Grid.kilogram");
      }

      return str;
    },
    isSelected(item) {
      const intersected = _.intersectionBy(this.selected, [item]);

      let isSelected = false;

      if (intersected.length > 0) {
        isSelected = true;
      }

      return isSelected;
    },
    generateOrder() {
      const date = this.delivery.date;
      const time = this.delivery.time;

      this.tblLgtOc.fechaOc = `${date} ${time}`;
      
      delete this.tblLgtOc.formaPagoId;
      delete this.tblLgtOc.fechaModificacion;
      
      this.isLoading = true;

      axios
        .put(`api/v1/public/orders`, this.tblLgtOc)
        .then(() => {
          this.isLoading = false;

          this.$router.push(`/orders/${this.tblLgtOc.ocId}/payments`).catch(()=>{});
        })
        .catch((err) => {
          this.isLoading = false;

          this.$dialog({
            title: this.$t("dialog.error"),
            text: "Hubo un error al intentar realizar la compra, por favor intentelo de nuevo",
          });
        });
    },
    hasServices(selected) {
      return _.sumBy(selected, function (a) {
        if (a.esAgendable === "Y") {
          return 1;
        } else {
          return 0;
        }
      });
    },
    openAppointmentsDialogSingle() {
      const selected = this.selected;

      const hasServices = this.hasServices(selected);

      if (hasServices) {
        this.$dialog(
          { items: selected, visible: true, finishSimpleCheckoutFlag:true},
          NAppointmentDialog
        ).then((res) => {

          this.addOrIncrementMany(res);

          this.finishSimpleCheckoutFlag=false;

        });
      } else {
        this.addOrIncrementMany(selected);
      }
    },
    // METODOS DEL CARRITO
    addOrIncrementMany(selected) {

      const first = selected[0];

      const comercioId = _.get(first, 'tblCnfComercio.comercioId');

      let filtered = selected.filter((item)=>_.get(item, 'tblCnfComercio.comercioId')==comercioId);

      if(this.tblLgtOc.comercioId != comercioId){
        this.tblLgtOc.comercioId = comercioId;
      }

      const ocId = this.$route.params.ocId;

      this.isLoading = true;

      this.$store
        .dispatch("publicCart/addOrIncrementMany", {
          ocId,
          items: filtered,
        })
        .then((items) => {

          this.selected = [];

          this.items = items;

          this.checkIsScheduleable(this.items);

          this.$notify({
            text: this.$t("SimpleCheckout.cartNotification.success"),
          }).then(() => {});

        })
        .catch(() => {

          this.selected = [];

          this.$notify({
            text: this.$t("SimpleCheckout.cartNotification.error"),
          }).then(() => {});

        })
        .finally(() => {

          this.isLoading = false;
        });
    },
    removeFromCart(item, loading) {
      
      this.isDisabled = true;
     
      this.isLoading = loading;

      const ocId = this.$route.params.ocId;

      this.$store
        .dispatch("publicCart/removeItem", { ocId, item })
        .then((items) => {

          this.items = items;

          this.checkIsScheduleable(this.items);

        })
        .catch(() => {
          // DOES NOTHING
        })
        .finally(() => {
          this.isDisabled = false;
          this.isLoading = false;
        })
    },
    checkIsScheduleable(details){
      this.isScheduleable = details.some(item => item.esAgendable ==='N');
    }
  },
};
</script>