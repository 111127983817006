var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"width":3,"color":"red","indeterminate":""}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"font-weight-bold"},[_c('p',[_vm._v(_vm._s(_vm.$t("Checkout.wait")))])])],1)],1),_c('n-panels',{scopedSlots:_vm._u([{key:"left",fn:function(){return [(!_vm.isDisabled && _vm.tblLgtOc.tblEntEntidad)?_c('filters-panel',{attrs:{"entidad-id":_vm.tblLgtOc.tblEntEntidad.entidadId},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}):_vm._e()]},proxy:true},{key:"center",fn:function(){return [(!_vm.isDisabled && _vm.tblLgtOc.tblEntEntidad)?_c('div',{attrs:{"width":"100%"}},[_c('n-grid',{attrs:{"label":_vm.$t('Home.title1'),"src":_vm.productsUrl,"filters":{
            'precioUnitario': _vm.filters.precioUnitario.join(':'),
            'producto': _vm.filters.producto,
            'tblLgtGrpProducto.grpProductoId': _vm.filters.categories.join(',')
          },"filter-operators":{
            'precioUnitario': 'between',
            'producto': 'like',
            'tblLgtGrpProducto.grpProductoId': 'in'
          },"order-by":"precioUnitario","sort-by":_vm.filters.sortBy,"col-sm":"12","col-md":"12","col-lg":"12","col-xl":"12"},on:{"reset":function () { return _vm.selected = []; }},scopedSlots:_vm._u([{key:"append-header",fn:function(ref){
          var isLoading = ref.isLoading;
return [(_vm.selected.length > 0)?_c('v-btn',{staticClass:"ml-3 text-capitalize",attrs:{"rounded":"","color":"primary","loading":isLoading},on:{"click":function () { return _vm.openAppointmentsDialogSingle(); }}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("Grid.add"))+" ")],1):_c('v-toolbar-items',[_c('v-text-field',{attrs:{"placeholder":_vm.$t('AppBar.search'),"outlined":"","rounded":"","dense":"","clearable":"","hide-details":"","single-line":"","prepend-inner-icon":"mdi-magnify","disabled":isLoading},model:{value:(_vm.filters.producto),callback:function ($$v) {_vm.$set(_vm.filters, "producto", $$v)},expression:"filters.producto"}})],1)]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-card',{staticClass:"shadow-sm"},[_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":item},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-img',{staticClass:"mr-4",attrs:{"src":item.imagen || '/img/default_product_image.png',"max-width":"52","min-width":"52","aspect-ratio":1}}),_c('v-list-item-content',[_c('span',{staticClass:"text-uppercase font-weight-regular text-caption"},[(item.esAgendable === 'Y' && item.esCalendarizado == 'Y')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar-check")]):(item.esAgendable == 'Y' && item.esCalendarizado == 'N')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar-remove")]):_vm._e(),_vm._v(" "+_vm._s(item.producto)+" ")],1),(item.tblLgtGrpProducto)?_c('div',{staticClass:"font-weight-regular text-caption",domProps:{"textContent":_vm._s(item.tblLgtGrpProducto.grpProducto)}}):_vm._e(),(item.precioNeto)?_c('div',{staticClass:"font-weight-bold text-caption",domProps:{"textContent":_vm._s(("$ " + ((item.precioNeto || 0).toFixed(2))))}}):_vm._e()]),(_vm.isSelected(item))?_c('v-list-item-action',[_c('v-text-field',{attrs:{"type":"number","min":1,"dense":"","hide-details":"","outlined":""},on:{"keydown":function (evt) {
                      if (['e', 'E', '+', '-'].includes(evt.key)) {
                        evt.preventDefault();
                      }
                    },"change":function (v) {
                      if (v == undefined || v == null || v == '') {
                        item.cantidadCaja = 0;
                      }
                    }},model:{value:(item.cantidadCaja),callback:function ($$v) {_vm.$set(item, "cantidadCaja", $$v)},expression:"item.cantidadCaja"}})],1):_vm._e()],1)],1)]}}],null,false,2945758592)})],1):_vm._e()]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('v-form',{ref:"checkoutForm"},[_c('v-row',[(_vm.isScheduleable)?_c('n-date-picker',{attrs:{"label":_vm.$t('Checkout.deliveryDate'),"rules":[_vm.$v.required, _vm.$v.futureOrEquals()],"cols":"12"},model:{value:(_vm.delivery.date),callback:function ($$v) {_vm.$set(_vm.delivery, "date", $$v)},expression:"delivery.date"}}):_vm._e(),(_vm.isScheduleable)?_c('n-time-picker',{attrs:{"label":_vm.$t('Checkout.deliveryTime'),"rules":[
                _vm.$v.required,
                _vm.$v.futureOrEquals(
                  null,
                  'YYYY-MM-DD HH:mm',
                  function (time) { return ((_vm.delivery.date) + " " + time); }
                ) ],"cols":"12"},model:{value:(_vm.delivery.time),callback:function ($$v) {_vm.$set(_vm.delivery, "time", $$v)},expression:"delivery.time"}}):_vm._e()],1)],1)],1),_c('order-details',{attrs:{"items":_vm.items,"editable":false,"price-key":"precioUnitario","enable-delete":""},on:{"delete":function (item){ return _vm.removeFromCart(item); }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
              var count = ref.count;
return [_c('v-btn',{staticClass:"text-capitalize font-600 mb-4",attrs:{"color":"primary","block":"","disabled":count < 1},on:{"click":function () { return _vm.generateOrder(); }}},[_vm._v(" "+_vm._s(_vm.$t("Checkout.placeOrder"))+" ")])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }